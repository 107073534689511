.notFound{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  h3{
    padding-bottom: 40px;
  }
  h2{
    color: white;
    font-size: 280px;
    position: absolute;
    z-index: -1;
    opacity: 20%;
    margin-bottom: 50px;
  }
}