@import "./../../styles/colors";
@import "./../../styles/responsive";

.offer {
  position: relative;

  .background {
    position: absolute;
    width: 100vw;
    height: auto;
    object-fit: cover;
    top: 0;
    z-index: -1;
    @include mobile {
      width: 1000px;
      transform: translateX(-190px);
    }
  }

  h2 {
    color: $white;
  }

  h3{
    padding-bottom: 10px;
  }

  p{
    color: $white;
    &.description{
      text-align: center;
      padding-bottom: 50px;
      max-width: 800px;
      margin: 0 auto;
    }
  }

  .box{
    >div{
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 15px 0;
      p{
        max-width: 820px;
      }
      &.right{
        align-items: flex-end;
        p{
          text-align: right;
        }
      }
    }
  }
}