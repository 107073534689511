@import "./../styles/responsive";
@import "./../styles/colors";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  background-color: $black;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(38, 38, 38);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $orange;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $orange-hover;
}

h1 {
  font-size: 60px;
  @include mobile {
    font-size: 40px;
  }
}

h2 {
  font-size: 50px;
  color: black;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 30px;
  @include mobile {
    font-size: 35px;
  }
}

h3 {
  color: $orange;
  text-transform: uppercase;
  font-size: 30px;
  @include mobile {
    font-size: 25px;
  }
}

p{
  font-size: 24px;
  @include mobile {
    font-size: 20px;
  }
}

.container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 50px;
  @include mobile {
    padding: 20px;
  }
}