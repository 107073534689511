@import "./../../styles/responsive";
@import "./../../styles/colors";

.header {
  width: 100vw;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 10px 50px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5);
  transition: 0.5s;

  &.active {
    background-color: $black;
    transition: 0.5s;
  }

  @include mobile {
    padding: 10px 20px;
    height: 50px;
  }

  .logoContainer {
    display: flex;
    align-items: center;
    img {
      width: 200px;
      @include desktop-sm {
        width: 200px;
      }
    }
  }

  .hamburger {
    color: $white;
    display: none;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    height: 100%;
    svg {
      height: 30px;
      width: auto;
      transition: 0.5s;
    }

    &.active {
      svg {
        transform: rotate(315deg);

        path {
          fill: white;
        }
      }
    }

    @include tablet-and-mobile {
      display: flex;
    }
  }

  .wrapper {
    overflow: hidden;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    @include tablet-and-mobile {
      top: 60px;
      position: absolute;
      width: 100vw;
      left: 0;
      height: 100vh;
      padding: 0 15px;
    }
    @include mobile {
      top: 50px;
    }
  }

  .navContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    @include tablet-and-mobile {
      display: none;
      position: absolute;
      width: 100vw;
      left: 0;
      background-color: rgba(0, 0, 0, 0.9);
      padding: 0 15px;
    }
    @include tablet-and-mobile {
      display: block;
    }


    ul {
      list-style-type: none;
      display: flex;
      align-items: center;
      @include tablet-and-mobile {
        flex-direction: column;
      }

      a {
        text-decoration: none;
        display: block;
        color: $white;
      }

      li {
        color: $white;
        padding: 4px 40px;
        font-weight: 500;
        font-size: 22px;
        transition: 0.3s;
        cursor: pointer;
        white-space: nowrap;
        @include desktop-sm {
          padding: 5px 25px;
          font-size: 20px;
        }
        @include tablet-and-mobile {
          padding: 15px;
        }

        &.active {
          a{
            color: $orange;
            transition: 0.3s;
            cursor: default;
          }
        }
      }
    }

    .socials {
      display: flex;
      @include tablet-and-mobile {
        justify-content: center;
        padding: 20px 0;
      }

      a {
        cursor: pointer;
        display: block;
        padding: 2px 10px 0;
        font-size: 10px;
        @include desktop-sm {
          padding: 2px 2px 0;
        }
        @include tablet-and-mobile {
          padding: 2px 10px 0;
        }

        svg {
          height: 29px;
          @include desktop-sm {
            height: 23px;
          }
          @include tablet-and-mobile {
            height: 33px;
          }
        }

        path {
          transition: 0.3s;
        }

        &:hover {
          path {
            stroke: $orange;
            transition: 0.3s;
          }
        }
      }
    }
  }
}