@import "./../../styles/colors";
@import "./../../styles/responsive";

.contact {
  position: relative;
  overflow: hidden;
  .background{
    position: absolute;
    width: 100vw;
    height: auto;
    object-fit: cover;
    z-index: -1;
    @include mobile {
      width: 700px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .row{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .singleContact{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 50px;
      min-height: 200px;
      justify-content: space-between;
      text-decoration: none;
      color: $white;
      font-size: 25px;
      .titleDiv{
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          @include mobile{
            width: 30px;
          }
        }
      }
      .descDiv{
        padding: 30px 0;
      }
      h3{
        color: $white;
        margin-left: 5px;
      }
      p {
        color: $white;
        font-size: 25px;
      }
      button{
        width: 250px;
      }
    }
  }
  h2{
    color: $white;
  }

}