$desktop-width: 1920px;
$desktop-sm-width: 1366px;
$tablet-width: 1023px;
$mobile-width: 767px;
$mobile-sm-width: 380px;

@mixin screen-min-mobile {
  @media screen and (min-width: #{$mobile-width + 1px}) {
    @content;
  }
}

@mixin screen-min-desktop-sm {
  @media screen and (min-width: #{$desktop-sm-width + 1px}) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: #{$tablet-width}) and (min-width: #{$mobile-width + 1px}) {
    @content;
  }
}

@mixin tablet-with-height($height, $type: "max") {
  @media screen and (min-width: #{$tablet-width}) and (#{$type}-height: #{$height}) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin mobile-sm {
  @media screen and (max-width: #{$mobile-sm-width}) {
    @content;
  }
}

@mixin tablet-and-mobile {
  @media screen and (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop-sm {
  @media screen and (max-width: #{$desktop-sm-width}) {
    @content;
  }
}

@mixin desktop {
  @media screen and (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin desktop-xxl {
  @media screen and (min-width: #{$desktop-width + 1px}) {
    @content;
  }
}
