@import "./../../styles/colors";
@import "./../../styles/responsive";

.realizations {
  background-color: $white;
  display: flex;

  .center {
    width: 100%;
    text-align: center;
    margin: 40px 0 10px;
    @include mobile {
      margin-bottom: 30px;
    }

    .buttonA {
      text-align: center;
    }
  }

  .grid {
    display: flex;
    justify-content: center;
    max-width: 900px;
    flex-wrap: wrap;

    a {
      @include tablet-and-mobile {
        &:nth-child(7), &:nth-child(8), &:nth-child(9) {
          display: none;
        }
      }
    }


    .imageWrapper {
      background: radial-gradient($black, #656565);
      margin: 5px;
      width: 280px;
      height: 280px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      border-radius: 3px;
      @include mobile {
        width: 230px;
        height: 230px;
      }
      @media (max-width: 519px) {
        width: calc(100vw - 50px);
        height: calc(100vw - 50px);
      }

      &:hover {
        img, video {
          transform: scale(1.1);
          transition: 0.5s;
          filter: opacity(25%);
        }

        p {
          opacity: 1;
          transition: 0.5s;
        }

        svg {
          opacity: 30%;
        }
        @media (max-width: 519px) {
          svg, p{
            opacity: 0;
          }
          img, video{
            filter: none;

          }
        }
      }

      img {
        width: 280px;
        transition: 0.5s;
        @include mobile {
          width: 240px;
        }
        @media (max-width: 519px) {
          width: 100vw;
        }
      }

      svg {
        position: absolute;
        opacity: 0;
        width: 300px;
        transition: 0.5s;
      }

      video {
        width: 280px;
        height: auto;
        transition: 0.5s;
        @include mobile {
          width: 240px;
        }
        @media (max-width: 519px) {
          width: 100vw;
        }
      }

      p {
        position: absolute;
        color: $white;
        text-transform: uppercase;
        z-index: 2;
        text-align: center;
        opacity: 0;
        transition: 0.5s;
      }
    }
  }
}