@import "./../../styles/colors";
@import "./../../styles/responsive";

.about{
  background-color: $white;
  padding-bottom: 10px;
  .grid{
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 80px;
    @include tablet-and-mobile {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
    .logoDiv{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @include tablet-and-mobile {
        justify-content: center;
        padding-top: 30px;
      }
      .logo{
        max-width: 100%;
        height: auto;
      }
    }
  }
}

