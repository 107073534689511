@import "./../../styles/colors";
@import "./../../styles/responsive";

.footer{
  background-color: $black;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px 15px;
  p{
    color: $white;
    padding: 5px;
    font-size: 14px;
    &:nth-child(1){
      font-size: 20px;
    }
    a{
      color: $white;
      transition: 0.3s;
      text-decoration: none;
      &:hover{
        color: $orange;
        transition: 0.3s;
      }
    }
  }
}