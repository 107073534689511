@import "./../../styles/colors";
@import "./../../styles/responsive";

.herobanner {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;

  .gradientLayer {
    background: linear-gradient(to left, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.2));
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 1;
  }

  .bg {
    position: absolute;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
  }

  .container {
    max-width: 1600px;
    margin: 0 auto;
    padding: 20px 70px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .heroText {
    z-index: 2;
    padding-bottom: 20vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    h1 {
      text-transform: uppercase;
      color: $white;
      text-align: right;
      letter-spacing: 1px;
      &:nth-child(3){
        padding-bottom: 40px;
        @include mobile {
          padding-bottom: 30px;
        }
      }
    }
    .city{
      font-size: 26px;
      color: $orange;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      text-decoration: none;
      letter-spacing: 1px;
      &:hover{
        .fillMap{
          opacity: 1;
        }
      }
      @include mobile {
        font-size: 20px;
      }
      .fillMap{
        position: absolute;
        opacity: 0;
        transition: 0.3s;
      }
      img{
        height: 26px;
        width: auto;
        margin: 0 5px 5px 0;
        @include mobile {
          height: 20px;
        }
      }
    }
  }
  @include tablet-and-mobile {
    .gradientLayer {
      background: linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6), rgba(255, 255, 255, 0.1));
    }
    .container{
      justify-content: center;
      align-items: center;
      padding: 20px 20px;
    }
    .bg{
      width: auto;
      height: 100vh;
    }
    .heroText {
      display: flex;
      align-items: center;
      padding: 0;
      h1 {
        text-align: center;
      }
    }
  }
}