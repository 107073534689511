@import "./../../styles/colors";
@import "./../../styles/responsive";

.button {
  background-color: $orange;
  font-family: Teko, sans-serif;
  text-transform: uppercase;
  padding: 6px 40px;
  font-size: 30px;
  width: fit-content;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 2px;
  color: $black;
  @include mobile{
    font-size: 22px;
    padding: 4px 30px;
  }
  &:hover{
    transition: 0.3s;
    transform: scale(1.03);
    background-color: #ff850a;
  }
}